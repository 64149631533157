import React from "react"
import Layout from "../components/layout"


export default function AvisoLegal() {
    return (
        <Layout>
            <h1>AvisoLegal</h1>
            <p>Welcome to my Gatsby site.</p>
        </Layout>
    )
}